<template>
  <v-row>
    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="sCode" label="ucfe.code" />
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obData.terminal" label="ucfe.terminal" />
    </v-col>

    <v-col cols="12" lg="6" xl="4" v-if="!isCustomer && !isBranch">
      <form-field-text v-model="obData.password" label="ucfe.password" />
    </v-col>

    <v-col cols="12" lg="6" xl="4" v-if="!isCustomer && !isBranch">
      <form-field-text v-model="obData.url" label="ucfe.url">
        <template #append-outer>
          <ucfe-btn-validator :rut="rut" :value="obData" />
        </template>
      </form-field-text>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Inject, Prop, VModel, Vue } from "vue-property-decorator";
import { Ref, type UcfeCredentials } from "@/types/utils";
import UcfeBtnValidator from "@/modules/companies/components/UcfeBtnValidator.vue";

@Component({
  components: { UcfeBtnValidator },
})
export default class UcfeForm extends Vue {
  @VModel({ type: Object }) obData!: UcfeCredentials;
  @Prop(Boolean) readonly isBranch!: boolean;
  @Prop(String) readonly rut!: string;

  @Inject() isCustomerRef!: Ref<boolean>;

  get isCustomer() {
    return this.isCustomerRef?.value;
  }

  get sCode() {
    return this.obData.code;
  }

  set sCode(sValue: string) {
    this.$set(this.obData, "code", sValue);
    this.$emit("input", this.obData);
  }
}
</script>
