<template>
  <v-row>
    <v-col v-if="!isCustomer" cols="12">
      <v-subheader v-text="$t('form.tab.ucfe')" />
      <ucfe-form v-model="obUcfe" :is-branch="isBranch" />
    </v-col>

    <v-col cols="12">
      <v-divider />
      <v-subheader v-text="$t('form.tab.dgi')" />
      <firm-dgi-form v-model="obFirm" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Branch, FirmData, UcfeData } from "@planetadeleste/vue-mc-gw";
import { Vue, Component, VModel, Inject, Prop } from "vue-property-decorator";

import FirmDgiForm from "@/modules/companies/components/FirmDgiForm.vue";
import UcfeForm from "@/modules/companies/components/UcfeForm.vue";
import { Ref } from "@/types/utils";

@Component({ components: { FirmDgiForm, UcfeForm } })
export default class BranchDgi extends Vue {
  @VModel({ type: Object, default: () => ({}) }) obBranch!: Branch;
  @Prop(Boolean) readonly isBranch!: boolean;

  @Inject() isCustomerRef!: Ref<boolean>;

  get isCustomer() {
    return this.isCustomerRef?.value;
  }

  get obFirm() {
    return this.obBranch.get("firm", {});
  }

  set obFirm(obData: Partial<FirmData>) {
    this.obBranch.set("firm", obData);
  }

  get obUcfe() {
    return this.obBranch.get("ucfe", {});
  }

  set obUcfe(obData: Partial<UcfeData>) {
    this.obBranch.set("ucfe", obData);
  }
}
</script>
